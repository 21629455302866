import React, { Component } from 'react';
import { Card, Form, Layout, Input, text,Button, message, Select, Divider} from 'antd';
import './Setting.scss';
import Cookies from 'universal-cookie';
import intl from 'react-intl-universal';
import { Link } from "react-router-dom";
const cookies = new Cookies();
const { Option } = Select;

const { Header, Content, } = Layout;
class Setting extends Component {
  constructor(props){
    super(props);
    this.submit = this.submit.bind(this);
    this.state = {
      isShow:"none",
      inputWidth:"100%",
      areaCode:'86',
      color1:"#006dd3",
      color2:"#A8A8A8",
      domain : global.Api.domain_url(),
      store: null,
    }
  }

  componentDidMount(){
  }


  handleChange = (value) => {
    value = Number(value);
    return value
  }

  handleChangeOversea = (value) => {
    this.setState({
      areaCode:value
    })
  }
  showSelect = (code)=>{
    if(code===0){
      this.setState({
            isShow:"none",
            inputWidth:"100%",
            color1:"#006dd3",
            color2:"#A8A8A8",
      })
    }else {
      this.setState(
          {
            isShow:"inline-block",
            inputWidth:"70%",
            color1:"#A8A8A8",
            color2:"#006dd3"
        }
      )
    }

  }

  submit(e){
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        global.Api.addStore(values).then(res=>{
          if(res.data.code === 0){ 
            message.info('Add successfully!'); 
            this.goback();    
            
          }else
            message.error(res.data.message);
        })
      }
    });
  }

  goback(){
    window.history.back();
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    
    return (
      <Layout className="reports">
        <Header style={{position: 'fixed', zIndex: 1, width: '100%',height:'120px',backgroundColor:'#3385ff',color:"#fff",}}>
          <div style={{marginRight:"20px",color:"#fff"}} >
          <span style={{fontSize:"20px",color:"#fff"}}>Handy Tyre Scanner</span>
          {/* <img class="logo img-fluid" src="https://mobilhubdiagnostics.com/img/logo-1667829529.jpg" alt='' width="228" height="38" /> */}
          </div>
          <Button type="link" style={{marginLeft:"10px",color:"#fff",fontSize:'15px',fontWeight:'500',}} ><Link to="/reports">{intl.get('reports')}</Link></Button>
          <Button type="link" style={{marginLeft:"10px",color:"#fff",fontSize:'15px',fontWeight:'500',}} ><Link to="/devices">{intl.get('devices')}</Link></Button>
          <Button type="link" style={{marginLeft:"10px",color:"#fff",fontSize:'15px',fontWeight:'500',}} ><Link to="/storelist">{intl.get('storemanagement')}</Link></Button>
          
          <Button type="link" style={{marginRight:"10px",color:"#fff",fontSize:'15px',fontWeight:'500',}} ><Link to="/setting">{intl.get('setting')}</Link></Button>
        </Header>
        <Content style={{ padding: '0 50px', marginTop:'110px'}}>
          <div className="setting">
            {/* <Button type="primary" style={{position:"absolute",left:"20px",top:"18px"}} onClick={this.downloadApp}>{intl.get('awd')}</Button> */}
            <Card title={intl.get('addstore')} style={{ width: 1000 }}>
            
            <Form onSubmit={this.submit}>
              <Form.Item>
                <text>Store Name</text>
                
                {getFieldDecorator('name', {
                  rules: [{ required: true, message: 'please input store name' }],
                })(
                <Input />
                )}
              </Form.Item>
              <Form.Item>
                <text>Store Province</text>
                {getFieldDecorator('province', {
                  rules: [{ required: true, message: 'please input store province' }],
                })(
                <Input />
                )}
              </Form.Item>
              <Form.Item>
                <text>Store City</text>
                {getFieldDecorator('city', {
                  rules: [{ required: true, message: 'please input store city' }],
                })(
                <Input />
                )}
              </Form.Item>
              <Form.Item>
                <text>Store Address</text>
                {getFieldDecorator('address', {
                  rules: [{ required: true, message: 'please input store address' }],
                })(
                <Input />
                )}
              </Form.Item>
              <Form.Item>
                <text>Store Telephone</text>
                {getFieldDecorator('contact', {
                  rules: [{ required: true, message: 'please input store contact telephone' }],
                })(
                <Input />
                )}
              </Form.Item>
              <Form.Item>
                <text>Store Email</text>
                {getFieldDecorator('email', {
                  rules: [{ required: true, message: 'please input store email' }],
                })(
                <Input />
                )}
              </Form.Item>
              <Form.Item>
                <text>Manager Name</text>
                {getFieldDecorator('staff_name', {
                  rules: [{ required: true, message: 'please input manager name' }],
                })(
                <Input />
                )}
              </Form.Item>
              <Form.Item>
                <text>Manager Email</text>
                {getFieldDecorator('staff_contact', {
                  rules: [{ required: true, message: 'please input manager email' }],
                })(
                <Input />
                )}
              </Form.Item>

              <Form.Item>
                <Button type="primary" htmlType="submit" style={{marginLeft:"0%",width:'45%'}}>
                {intl.get('confirm')}
                </Button>
                <Button type="primary" onClick={this.goback} style={{marginLeft:"10%",width:'45%'}}>
                {intl.get('cancel')}
                </Button>
              </Form.Item>
            </Form>
            </Card>
          </div>
        </Content>
      </Layout>
    );
  }
}

export default Form.create()(Setting);
