/* eslint-disable default-case */
import React, { Component } from 'react';
import { Layout, message,Table, Button } from 'antd';
import intl from 'react-intl-universal';
import { Link } from "react-router-dom";
import './List.scss';
import Cookies from 'universal-cookie';
import { inject, observer } from 'mobx-react';
import { DatePicker } from "antd";
import "antd/dist/antd.css";
import * as echarts from "echarts";

const cookies = new Cookies();
const { Header, Content, } = Layout;

@observer
class Dashboard extends Component {
  constructor(props){
    super();
    this.state = {
      domain : global.Api.domain_url(),
      lang : intl.options.currentLocale,
      loading: false,
      dt_start:'',
      dt_end:'',
      chances_rate: [],
      reports_stats_dates:[],
      reports_stats_daily:[],
      chances_stats_daily:[],
      reports_total:0,
    }
  }

  componentDidMount(){
    if (this.state.lang === 'ge'){
      this.setState({
          lang:'de'
      })
    }else if (this.state.lang === 'sp'){
        this.setState({
            lang:'es'
        })
    }else if (this.state.lang === 'ma'){
      this.setState({
          lang:'ms'
      })
    }else if (this.state.lang === 'lt'){
      this.setState({
          lang:'it'
      })
    }else if (this.state.lang === 'tw'){
      this.setState({
          lang:'zh_TW'
      })
    }else if (this.state.lang === 'jp'){
      this.setState({
          lang:'ja'
      })
    }else if (this.state.lang === 'po'){
      this.setState({
          lang:'pl'
      })
    }else if (this.state.lang === 'por'){
      this.setState({
          lang:'pt'
      })
    }else if (this.state.lang === 'dk'){
      this.setState({
          lang:'da'
      })
    }

    this.getStatisticsData()

  }
  
  // 获取列表
  getStatisticsData = (params = {dt_start: this.state.dt_start, dt_end: this.state.dt_end}) => {
    this.setState({ loading: true });
    global.Api.getStatisticsData({
      ...params
    }).then(res=>{
      if (res.data.code === 0) {
        let _data = res.data.data;    

        this.setState({
          loading: false,
          chances_rate: _data.chances_rate,
          reports_stats_dates: _data.reports_stats_dates,
          reports_stats_daily: _data.reports_stats_daily,
          chances_stats_daily: _data.chances_stats_daily,
          reports_total: _data.total_reports,
        });
        const chances_ratio = echarts.init(document.getElementById('chances_ratio'));        
        chances_ratio.setOption ({
          backgroundColor:'#f4f4f4',
          title: {
            text: 'Chances Ratio',
            x: 'center',
            y: 'top'
          },
          series: [
            {
              type: 'pie',
              // 自动显显示数据开始 start-----------------
                itemStyle: {	
                  normal: {
                      label:{ 
                          show: true, 
                          formatter: '{b} : {c} ({d}%)' 
                      },
                      // color: '#0c9'
                      color: function (colors) {
                        var colorList=[
                          '#fc8251',
                          '#5470c6',
                          '#9A60B4',
                          '#ef6567',
                          '#f9c956',
                          '#3BA272'
                        ];
                        return colorList[colors.dataIndex];
                      }
                  },
                  labelLine :{show:true}
              },
              // 自动显显示数据开始 end--------------------
              // data: [
              //   { 'value': 335, 'name': 'Chance Reports' },
              //   { 'value': 310, 'name': 'Non-chance Reports' },
              // ],
              data:this.state.chances_rate,
            },
          ],
        });

        const records_charts = echarts.init(document.getElementById('records_charts'));        
        records_charts.setOption ({
          backgroundColor:'#f4f4f4',
          title: {
            text: 'Reports Charts',
            x: 'center',
            y: 'top'
          },
          tooltip: {},
          xAxis: {
            // data: ["2024/7/20", "2024/7/21", "2024/7/22", "2024/7/23", "2024/7/24", "2024/7/25"]
            data: this.state.reports_stats_dates
          },
          yAxis: [
            {
              type:'value',
              splitNumber: 5, //Y轴坐标精度，默认5
            }
          ],
          series: [{
            name: 'Reports',
            type: 'bar',
            // data: [100, 20, 30, 10, 10, 200]
            data: this.state.reports_stats_daily,
            // 自定义Y轴颜色
            itemStyle: {
              normal: {
                color: '#0cf'           // 柱子颜色
              }
            },
          }]
        });

        const chances_charts = echarts.init(document.getElementById('chances_charts'));        
        chances_charts.setOption ({
          backgroundColor:'#f4f4f4',
          title: {
            text: 'Chances Charts',
            x: 'center',
            y: 'top'
          },
          tooltip: {},
          xAxis: {
            // data: ["2024/7/20", "2024/7/21", "2024/7/22", "2024/7/23", "2024/7/24", "2024/7/25"]
            data: this.state.reports_stats_dates
          },
          yAxis: [
            {
              type:'value',
              splitNumber: 5, //Y轴坐标精度，默认5
            }
          ],
          series: [{
            name: 'Chances',
            type: 'bar',
            // data: [100, 20, 30, 10, 10, 200]
            data: this.state.chances_stats_daily,
            // 自定义Y轴颜色
            itemStyle: {
              normal: {
                color: '#fc9'           // 柱子颜色
              }
            },
          }]
        });

      }else 
        message.error(res.data.message);      
    })
  }
  
  setStartDate = (date) =>{
    this.setState({
      dt_start: date,
    });
  }

  setEndDate = (date) =>{
    this.setState({
      dt_end: date,
    });
  }

  render() {
   
    return (
      <Layout className="reports">
        <Header style={{position: 'fixed', zIndex: 1, width: '100%',height:'120px',backgroundColor:'#3385ff',color:"#fff",}}>
          <div style={{marginRight:"20px",color:"#fff"}} >
            <span style={{fontSize:"20px",color:"#fff"}}>Handy Tyre Scanner</span>
          {/* <img class="logo img-fluid" src="https://mobilhubdiagnostics.com/img/logo-1667829529.jpg" alt='' width="228" height="38" /> */}
          </div>
          
          <Button type="link" style={{marginLeft:"10px",color:"#fff",fontSize:'15px',fontWeight:'500',}} ><Link to="/dashboard">{intl.get('dashboard')}</Link></Button>
          <Button type="link" style={{marginLeft:"10px",color:"#fff",fontSize:'15px',fontWeight:'500',}} ><Link to="/dealerlist">{intl.get('reports')}</Link></Button>
          {/* <Button type="link" style={{marginLeft:"10px",color:"#fff",fontSize:'15px',fontWeight:'500',}} ><Link to="/devices">{intl.get('devices')}</Link></Button>
          <Button type="link" style={{marginLeft:"10px",color:"#fff",fontSize:'15px',fontWeight:'500',}} ><Link to="/storelist">{intl.get('storemanagement')}</Link></Button> */}
          
          <Button type="link" style={{marginRight:"10px",color:"#fff",fontSize:'15px',fontWeight:'500',}} ><Link to="/settingdealer">{intl.get('setting')}</Link></Button>
          {/* {(this.props.store.user_type === 2 || this.props.store.user_type === 3) && (<Button type="link" style={{marginRight:"10px",color:"#fff",fontSize:'15px',fontWeight:'500',}} ><Link to="/setting">{intl.get('setting')}</Link></Button>
          )} */}
          {/* <span style={{marginRight:"10px",fontSize:"10px",color:"#fff"}}>{this.props.store.user['name']}</span> */}
        </Header>
        <Content style={{ padding: '0 50px', marginTop:'120px'}}>
          <div style={{ background: '#fff', padding: 25,display:'flex'}}>
            {/* <DatePicker  /> */}
            <DatePicker onChange={this.setStartDate}/>
            <span style={{padding:'5px 5px'}}>-</span>
            <DatePicker onChange={this.setEndDate}/>
            <button style={{marginLeft: '20px',padding: '2px 12px',border:'none',fontColor:'#fff',fontSize:'16px',fontWeight:'500',height:'35px',width:'80px',borderRadius:'10px 10px 10px 10px',backgroundColor:'#eee'}} onClick={() => this.getStatisticsData()}>{intl.get('search_btn')}</button>
            <span style={{marginLeft: '20px',marginTop: '15px',fontSize:'12px',}}>(*Default show the last 30 days data.)</span>
          </div>
          <div style={{ marginTop: '20px',marginRight: '20px',display:'flex',flexDirection:'row',alignItems:'right',justifyContent:'right'}}><span>Reports Total : {this.state.reports_total}</span></div>
          <div id="chances_ratio" style={{ marginTop: '20px',height:'400px',width:'100%',display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'center'}}> </div>
          <div id="records_charts" style={{marginTop: '20px', height:'400px',width:'100%',display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'center'}}> </div>
          <div id="chances_charts" style={{marginTop: '20px', height:'400px',width:'100%',display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'center'}}> </div>
        
        </Content>
      </Layout>
    );
  }
}

export default Dashboard;
